<template>
  <div class="container">
    <van-form @submit="onSubmit" :label-width="70" :show-error="false" scroll-to-error v-if="status==0">
      <van-cell-group :border="false">
        <van-field :label-width="40" v-model="json.name" label="姓名" required placeholder="填写姓名" :rules="[{ required: true,message: '请填写姓名'}]"/>
        <van-field :label-width="40" v-model="json.money" label="额度" required placeholder="填写申请额度" :rules="[{ required: true,message: '请填写申请额度'}]">
          <template slot="button">元</template>
        </van-field>
        <van-field :label-width="40" v-model="json.date" label="分期" required placeholder="填写分期时限" :rules="[{ required: true,message: '填写分期时限'}]">
          <template slot="button">个月</template>
        </van-field>
      </van-cell-group>
      <div class="mt10"><van-button round block type="info" native-type="submit" :loading="loading">立即申请</van-button></div>
    </van-form>

    <div class="result" v-else>
      <div class="img">
        <img :src="oss+'loan.png'" width="180"/>
      </div>
      <div class="title">恭喜，{{json.name}}已通过我司预审！</div>
      <div class="divider">
        <van-divider dashed :style="{borderColor: '#E7E7E7',margin:'30px 0px'}"/>
      </div>

      <van-divider contentPosition="center" :style="{borderColor:'#999999',fontSize:'18px'}">您的预审额度最高至</van-divider>
      <div class="money">¥<span>{{json.money}}</span></div>
      <div class="date">最长可分期期限{{json.date}}个月</div>

      <div class="evaluate">
        <p>您对本次「预约流程」满意吗？</p>
        <p><a>去评价 ></a></p>
      </div>

      <div class="divider">
        <van-divider dashed :style="{borderColor: '#E7E7E7',margin:'30px 0px'}"/>
      </div>
      <van-divider contentPosition="center" :style="{borderColor:'#999999',fontSize:'18px'}">后续流程</van-divider>
      <div class="content">
        <p class="h4">1、面签</p>
        <p>我司当地贷款专员将于1个工作日内，电话与您联系预约面签时间。</p>
        <p>请提前准备以下资料原件:</p>
        <p>(1)近一年银行流水电子版xls、xlsx和csv格式)和流水原件</p>
        <p>(2)企业营业执照</p>
        <p>(3)房产证明</p>
        <p>门店地址:江苏省南通市COCOSPACE南通创新中心D座903室</p>
      </div>

    </div>
  </div>
</template>
<script>
  export default {
    name:'loan',
    components: {},
    data () {
      return {
        status:0,
        json:{
          name:'',
          money:'',
          date:''
        },
        loading:false
      }
    },
    created(){
    },
    computed:{
    },
    mounted(){

    },
    methods:{
      onSubmit(){
        this.status = 1
        this.json.money = this.json.money.replace(/\B(?=(\d{3})+$)/g,',')+'.00'
        document.title = '申请结果'
      }
    }
  }

</script>
<style lang="less" scoped>
  .container{padding:10px;
    .result{background:#fff;padding:20px;width:100%;overflow:hidden;box-sizing:border-box;-moz-box-sizing:border-box;-webkit-box-sizing:border-box;
      .img{text-align:center;}
      .title{text-align:center;font-size:20px;font-weight:700;}
      .money{font-size:24px;text-align:center;margin-top:20px;
        span{font-size:44px;margin-left:4px;}
      }
      .date{text-align:center;color:#999;margin-top:10px;font-size:18px;}
    }
    .evaluate{background:rgba(237, 119, 121, 0.06) url('@{static-dir}loan_icon.png') no-repeat 10px center;background-size:52px auto;border:1px solid #C1C1C1;margin-top:30px;border-radius:8px;padding:16px 0px 16px 72px;
      p{color:#545454;font-size:16px;}

    }
    .content{
      .h4{font-weight:700;color:#333;}
      p{padding:2px 0px;line-height:20px;color:#444;}
    }
    .divider{position:relative;
      &::after{content:'';width:30px;height:30px;background:#f5f5f5;position:absolute;right:-35px;top:-15px;z-index:1;border-radius:30px;}
      &::before{content:'';width:30px;height:30px;background:#f5f5f5;position:absolute;left:-35px;top:-15px;z-index:1;border-radius:30px;}
    }
  }
</style>
